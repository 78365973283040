const videos = [
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802545274/rendition/720p/file.mp4?loc=external&signature=2ad3f9309f2d5a1cff9311da0b58ee6cf33ba6723ea278cd789fa3ba06f1cbc1', type: 'video', title: 'RETROUVE'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802545263/rendition/720p/file.mp4?loc=external&signature=1cd6aca4c6535391a2c67a3d2f8874c49352806d5df203fce5a3e1225ce2c74f', type: 'video', title: 'REN'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802545261/rendition/720p/file.mp4?loc=external&signature=10070bc9bb35db4c96105d151d40a53b54dd10315179238c81cffc9f533855b9', type: 'video', title: 'HONEYPOT'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802545254/rendition/720p/file.mp4?loc=external&signature=10324a91e767a1d4c9852364b166979bddab9ebdfbf32b3ec0b34cc25598e487', type: 'video', title: 'HONEYPOT'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802545233/rendition/720p/file.mp4?loc=external&signature=7e70b1b3d752efdc09be2b003d3137e83b13dcf8e115bef23d10f7b4360b5d1d', type: 'video', title: 'FP MOVEMENT'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802545219/rendition/720p/file.mp4?loc=external&signature=aeeff0ab1abc19c0866f376479ae2d3cf40c15b1cdd2ad82af4b463b09473fdf', type: 'video', title: 'FP MOVEMENT'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802545202/rendition/720p/file.mp4?loc=external&signature=22c7de05a5d8f07ac9462dd859e78117c109b6fffcc62f9accb0af3010fa33b9', type: 'video', title: 'FP MOVEMENT'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802545174/rendition/720p/file.mp4?loc=external&signature=57e09cb000956d8bca65120053f586489876aad3a6e8941302a3ccb881674753', type: 'video', title: 'FACILE'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802545150/rendition/720p/file.mp4?loc=external&signature=11bac5fba5b2c330449c4555a623f9a5a181e06a755f2dd7267b08fde068a5db', type: 'video', title: 'FACILE'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802545143/rendition/540p/file.mp4?loc=external&signature=3e26c62577baa950e79b74ea63162172b44d407843a2cb48ea0f6e5e4c139858', type: 'video', title: 'FACILE'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802545123/rendition/720p/file.mp4?loc=external&signature=3b2895cd68f78862ca14139ea7769433bd31bdb6e1b9565faa3a2f008f3d8117', type: 'video', title: 'FACILE'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802545106/rendition/720p/file.mp4?loc=external&signature=642b5e181135787b603dfe370670a7266c2f2c162d1c2eaae32f45b83442b5bd', type: 'video', title: 'FACILE'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802545087/rendition/720p/file.mp4?loc=external&signature=e28e9c54fef4e43a3fca9016074ae522f3e868870d4ccfe44c9a1a6091ecc43b', type: 'video', title: 'DEDCOOL'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802545071/rendition/720p/file.mp4?loc=external&signature=79a1005223a7b40b6c1624f3108733fe192be548ad2978a65ca22b29c9bad271', type: 'video', title: 'DEDCOOL'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802545031/rendition/720p/file.mp4?loc=external&signature=5ee9663ed76e907df3b6757cdf7a9b20a732d05afc2f2d48d8bc4a3c351f0363', type: 'video', title: 'DEDCOOL'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802545006/rendition/720p/file.mp4?loc=external&signature=dde97ee014c3cc3ea603916b1482996c2438a3b2631bba85d3ed53e21624ec48', type: 'video', title: 'DEDCOOL'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802544994/rendition/720p/file.mp4?loc=external&signature=9c9d8980a8e9ed22d63ec1dfbb445233065a52d195f9acb29b89833618051efd', type: 'video', title: 'DEDCOOL'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802544957/rendition/720p/file.mp4?loc=external&signature=172658f8bb957ec12940be89a75c1b5c6bcf0176927d1fc3eb5a2c3ea3f5b1ff', type: 'video', title: 'DEDCOOL'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/802544950/rendition/720p/file.mp4?loc=external&signature=7a397227a3f23098fc0d1a7de59f53ed4751799e5a94053fdd0ff470b1decbb0', type: 'video', title: 'BRAGG'},
]

export default videos
