import React, {useEffect} from 'react'
import images from '../data/images'
import videos from '../data/videos'
import {PixiPlugin, gsap} from 'gsap/all'
import * as PIXI from 'pixi.js'
import {useRef} from 'react'

gsap.registerPlugin(PixiPlugin)
PixiPlugin.registerPIXI(PIXI)

const Index = () => {
    const ref = useRef([])
    useEffect(() => {
        if (typeof window !== 'undefined') {
            let previousText = ''
            let app = new PIXI.Application({
                width: window.innerWidth,
                height: window.innerHeight,
                view: document.querySelector('#app'),
                transparent: true,
                antialias: true,
                autoDensity: true,
                backgroundColor: '#fff',
            })

            PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH
            PIXI.settings.ROUND_PIXELS = true
            PIXI.settings.RESOLUTION = 2

            app.renderer.resize(window.innerWidth, window.innerHeight)
            app.renderer.events.autoPreventDefault = false
            app.renderer.view.style.touchAction = 'auto'
            const rectangle = new PIXI.Graphics()
            rectangle.beginFill(0x00ffff)
            rectangle.drawRect(0, 0, 200, 200)
            rectangle.endFill()
            rectangle.visible = false

            const rectTexture = app.renderer.generateTexture(rectangle)
            const rectSprite = new PIXI.Sprite(rectTexture)
            app.stage.addChild(rectSprite)

            const container = new PIXI.Container()
            container.x = window.innerWidth / 2
            container.y = window.innerHeight / 2

            let verticalMobileText = createMobileVerticalText()

            app.stage.addChild(container, verticalMobileText)

            const deskWidth = 2000
            const deskRatio = 16 / 9
            const containerSize = {
                x: {min: -deskWidth, max: deskWidth},
                y: {min: -deskWidth / deskRatio, max: deskWidth / deskRatio},
            }

            const visibleArea = {
                x: {min: containerSize.x.min - deskWidth * 0.1, max: containerSize.x.max + deskWidth * 0.05},
                y: {min: containerSize.y.min - deskWidth * 0.1, max: containerSize.y.max + deskWidth * 0.05},
            }

            const mobWidth = 520
            const mobRatio = 9 / 16
            const containerSizeMob = {
                x: {min: -mobWidth, max: mobWidth},
                y: {min: -mobWidth / mobRatio, max: mobWidth / mobRatio},
            }

            const visibleAreaMob = {
                x: {min: containerSizeMob.x.min - mobWidth * 0.18, max: containerSizeMob.x.max + mobWidth * 0.09},
                y: {min: containerSizeMob.y.min - mobWidth * 0.18, max: containerSizeMob.y.max + mobWidth * 0.09},
            }

            let desktopPositions = []
            let mobilePositions = []
            let regeneratedDesktop = 0
            let regeneratedMobile = 0
            const closenessD = 200
            const closenessM = 110

            function generatePosition() {
                return window.innerWidth >= 992 ? generateDesktop() : generateMobile()
            }

            function generateDesktop() {
                const xRand = Math.random() * (containerSize.x.max - containerSize.x.min) + containerSize.x.min
                const yRand = Math.random() * (containerSize.y.max - containerSize.y.min) + containerSize.y.min

                const closeAsset = desktopPositions.find(
                    ({x, y}) => Math.abs(x - xRand) < closenessD && Math.abs(y - yRand) < closenessD
                )

                if (closeAsset) {
                    regeneratedDesktop++
                    return generateDesktop()
                }

                const dPosition = {x: xRand, y: yRand}

                desktopPositions.push(dPosition)

                return dPosition
            }

            function generateMobile() {
                const xRand = Math.random() * (containerSizeMob.x.max - containerSizeMob.x.min) + containerSizeMob.x.min
                const yRand = Math.random() * (containerSizeMob.y.max - containerSizeMob.y.min) + containerSizeMob.y.min

                const closeAsset = mobilePositions.find(
                    ({x, y}) => Math.abs(x - xRand) < closenessM && Math.abs(y - yRand) < closenessM
                )

                if (closeAsset) {
                    regeneratedMobile++
                    return generateMobile()
                }

                const mPosition = {x: xRand, y: yRand}

                mobilePositions.push(mPosition)

                return mPosition
            }

            const videoInitialScale = window.innerWidth >= 992 ? 0.2 : 0.15
            const videoScaleValue = window.innerWidth >= 992 ? 0.3 : 0.2

            const imgInitialScale = window.innerWidth >= 992 ? 0.35 : 0.25
            const imgScaleValue = window.innerWidth >= 992 ? 0.7 : 0.4

            function canvasAssets(item) {
                if (item.type === 'video') {
                    const videoResource = new PIXI.VideoResource(item.src, {autoPlay: true, preload: 'auto'})
                    videoResource.updateFPS = 30 // set video to render at 30FPS to avoid performance issues

                    const texture = PIXI.Texture.from(videoResource)
                    texture.baseTexture.resource.source.muted = true
                    texture.baseTexture.resource.source.autoPlay = true
                    texture.baseTexture.resource.source.loop = true
                    texture.baseTexture.resource.source.playsInline = true

                    const char1Sprite = new PIXI.Sprite(texture)

                    char1Sprite.interactive = true
                    char1Sprite.anchor.set(0.9)

                    const assetPosition = generatePosition()

                    const xPos = assetPosition.x
                    const yPos = assetPosition.y
                    let initialValScale = videoInitialScale
                    let initialScaledVal = videoScaleValue

                    char1Sprite.position.x = xPos
                    char1Sprite.position.y = yPos

                    gsap.set(char1Sprite, {
                        pixi: {
                            scale: initialValScale,
                            zIndex: 1,
                        },
                    })

                    let startAction = window.innerWidth >= 992 ? 'mouseenter' : ''
                    let endAction = window.innerWidth >= 992 ? 'mouseleave' : ''

                    let title = loadText(item.title, 50)

                    char1Sprite.addEventListener(startAction, function () {
                        gsap.to(title, {
                            pixi: {
                                alpha: 1,
                            },
                        })

                        gsap.to(char1Sprite, {
                            pixi: {
                                scale: initialScaledVal,
                                zIndex: 50,
                            },
                        })
                    })

                    char1Sprite.addEventListener(endAction, function () {
                        gsap.to(title, {
                            pixi: {
                                alpha: 0,
                            },
                        })

                        gsap.to(char1Sprite, {
                            pixi: {
                                scale: initialValScale,
                                zIndex: 1,
                            },
                        })
                    })

                    char1Sprite.addEventListener('touchmove', (e) => {
                        updateVerticalText(e.currentTarget.children[0]._text)

                        container.children.forEach((ele) => {
                            gsap.to(ele, {
                                pixi: {
                                    scale: initialValScale,
                                    zIndex: 1,
                                },
                            })
                        })

                        gsap.to(e.currentTarget, {
                            pixi: {
                                scale: initialScaledVal,
                                zIndex: 50,
                            },
                        })
                    })

                    char1Sprite.addEventListener('touchend', function () {
                        gsap.to(char1Sprite, {
                            pixi: {
                                scale: initialValScale,
                                zIndex: 1,
                            },
                        })
                        gsap.to(verticalMobileText, {
                            pixi: {
                                alpha: 0,
                            },
                        })
                    })

                    container.addChild(char1Sprite)
                    container.sortableChildren = true

                    gsap.set(char1Sprite, {
                        pixi: {
                            scale: initialValScale,
                            zIndex: 1,
                        },
                    })

                    function loadText(text, positionY) {
                        if (!text) return
                        let textObject = new PIXI.Text(text, {
                            fontSize: 64,
                            letterSpacing: -0.64,
                            lineHeight: 80,
                        })

                        texture.baseTexture.resource._load.then((video) => {
                            textObject.x = -(texture.frame.width - texture.frame.width / 10)
                            textObject.alpha = 0
                            textObject.interactive = false
                            char1Sprite.addChild(textObject)
                            textObject.y =
                                -(texture.frame.height - texture.frame.height / 10) + (texture.frame.height + positionY)
                        })

                        return textObject
                    }
                } else {
                    const quality = window.innerWidth >= 992 ? 90 : 70

                    let itemSrc = `https://willa.imgix.net${item.src}?q=${quality}&auto=format`
                    if (!item.src.includes('static/media')) itemSrc = item.src
                    PIXI.Assets.load(itemSrc).then((texture) => {
                        const char1Sprite = new PIXI.Sprite(texture)
                        char1Sprite.interactive = true

                        const assetPosition = generatePosition()

                        const xPos = assetPosition.x
                        const yPos = assetPosition.y
                        let initialValScale = imgInitialScale
                        let initialScaledVal = imgScaleValue

                        char1Sprite.position.x = xPos
                        char1Sprite.position.y = yPos

                        gsap.set(char1Sprite, {
                            pixi: {
                                scale: initialValScale,
                                zIndex: 1,
                            },
                        })

                        let startAction = window.innerWidth >= 992 ? 'mouseenter' : ''
                        let endAction = window.innerWidth >= 992 ? 'mouseleave' : ''

                        let title = loadText(item.title, 70)

                        char1Sprite.addEventListener(startAction, function () {
                            ref.current = this
                            gsap.to(title, {
                                pixi: {
                                    alpha: 1,
                                },
                            })

                            gsap.to(char1Sprite, {
                                pixi: {
                                    scale: initialScaledVal,
                                    zIndex: 50,
                                },
                            })
                        })

                        char1Sprite.addEventListener(endAction, function () {
                            gsap.to(title, {
                                pixi: {
                                    alpha: 0,
                                },
                            })

                            gsap.to(char1Sprite, {
                                pixi: {
                                    scale: initialValScale,
                                    zIndex: 1,
                                },
                            })
                        })

                        const initialValScaleImage = imgInitialScale
                        const initialScaledValImage = imgScaleValue

                        const initialValScaleVideo = videoInitialScale
                        const initialScaledValVideo = videoScaleValue

                        char1Sprite.addEventListener('touchmove', (e) => {
                            updateVerticalText(e.currentTarget.children[0]._text)

                            let url = e.currentTarget._texture.baseTexture.resource.src,
                                type = url ? 'image' : 'video'

                            container.children.forEach((chidItem) => {
                                if (url !== chidItem._texture.baseTexture.resource.src) {
                                    const initialScaledVal =
                                        chidItem._texture.baseTexture.resource.src === undefined
                                            ? initialScaledValVideo
                                            : initialScaledValImage
                                    const initialValScale =
                                        chidItem._texture.baseTexture.resource.src === undefined
                                            ? initialValScaleVideo
                                            : initialValScaleImage

                                    if (chidItem.scale._x === initialScaledVal) {
                                        gsap.to(chidItem, 0.4, {
                                            pixi: {
                                                scale: initialValScale,
                                            },
                                        })
                                    }
                                }
                            })

                            const initialScaledVal = type === 'video' ? initialScaledValVideo : initialScaledValImage

                            gsap.to(e.currentTarget, {
                                pixi: {
                                    scale: initialScaledVal,
                                },
                            })
                        })

                        char1Sprite.addEventListener('touchend', function (e) {
                            setTimeout(() => {
                                container.children.forEach((chidItem) => {
                                    const initialValScale =
                                        chidItem._texture.baseTexture.resource.src === undefined
                                            ? initialValScaleVideo
                                            : initialValScaleImage

                                    gsap.to(chidItem, 0.4, {
                                        pixi: {
                                            scale: initialValScale,
                                        },
                                    })
                                })
                            }, 400)

                            gsap.to(verticalMobileText, {
                                pixi: {
                                    alpha: 0,
                                },
                            })
                        })

                        char1Sprite.anchor.set(0.9)
                        container.addChild(char1Sprite)
                        container.setChildIndex(char1Sprite, 1)
                        container.sortableChildren = true

                        gsap.set(char1Sprite, {
                            pixi: {
                                scale: initialValScale,
                                zIndex: 1,
                            },
                        })

                        function loadText(text, positionY) {
                            if (!text) return
                            let textObject = new PIXI.Text(text, {
                                fontSize: 32,
                                letterSpacing: -0.64,
                                lineHeight: 20,
                            })

                            textObject.x = -(texture.frame.width - texture.frame.width / 10)
                            textObject.alpha = 0
                            textObject.interactive = false
                            if (positionY) textObject.y = positionY
                            char1Sprite.addChild(textObject)

                            return textObject
                        }
                    })
                }
            }

            if (window.innerWidth >= 992) {
                for (let i = 0; i < videos.length; i++) {
                    const videoItem = videos[i]
                    canvasAssets(videoItem)
                }
            }

            for (let i = 0; i < images.length; i++) {
                const imageItem = images[i]
                canvasAssets(imageItem)
            }

            //move canvas on desktop
            // eslint-disable-next-line no-undef
            let move = {x: 0, y: 0}
            container.onglobalmousemove = function (events) {
                if (window.innerWidth < 992) return

                const {x, y} = events.client

                const xPositionPercent = x / window.innerWidth
                const yPositionPercent = y / window.innerHeight

                const xPosition =
                    (visibleArea.x.max - visibleArea.x.min - window.innerWidth) * xPositionPercent + visibleArea.x.min
                const yPosition =
                    (visibleArea.y.max - visibleArea.y.min - window.innerHeight) * yPositionPercent + visibleArea.y.min

                gsap.to(this, {
                    x: move.x,
                    y: move.y,
                    duration: 5,
                })

                rectSprite.x = x - rectSprite.width / 2
                rectSprite.y = y - rectSprite.width / 2
                move.x = -xPosition
                move.y = -yPosition

                const initialValScaleImage = imgInitialScale
                const initialScaledValImage = imgScaleValue

                const initialValScaleVideo = videoInitialScale
                const initialScaledValVideo = videoScaleValue

                let cursorRect = rectSprite.getBounds()

                let items = this.children.filter((item) => {
                    let itemRect = item.getBounds()
                    return isCollide(itemRect, cursorRect)
                })

                if (!items.length) {
                    container.children.forEach((chidItem) => {
                        const initialScaledVal =
                            chidItem._texture.baseTexture.resource.src === undefined
                                ? initialScaledValVideo
                                : initialScaledValImage
                        const initialValScale =
                            chidItem._texture.baseTexture.resource.src === undefined
                                ? initialValScaleVideo
                                : initialValScaleImage

                        if (chidItem.scale._x === initialScaledVal) {
                            gsap.to(chidItem, 0.4, {
                                pixi: {
                                    scale: initialValScale,
                                },
                            })
                        }
                    })
                }

                ref.current = items

                ref.current.forEach((item) => {
                    let url = item._texture.baseTexture.resource.src,
                        type = url ? 'image' : 'video'

                    container.children.forEach((chidItem) => {
                        if (url !== chidItem._texture.baseTexture.resource.src) {
                            const initialScaledVal =
                                chidItem._texture.baseTexture.resource.src === undefined
                                    ? initialScaledValVideo
                                    : initialScaledValImage
                            const initialValScale =
                                chidItem._texture.baseTexture.resource.src === undefined
                                    ? initialValScaleVideo
                                    : initialValScaleImage

                            if (chidItem.scale._x === initialScaledVal) {
                                gsap.to(chidItem, 0.4, {
                                    pixi: {
                                        scale: initialValScale,
                                    },
                                })
                            }
                        }
                    })

                    const initialScaledVal = type === 'video' ? initialScaledValVideo : initialScaledValImage

                    gsap.to(item, {
                        pixi: {
                            scale: initialScaledVal,
                        },
                    })
                })

                function isCollide(aRect, bRect) {
                    return !(
                        aRect.top + aRect.height < bRect.top ||
                        aRect.top > bRect.top + bRect.height ||
                        aRect.left + aRect.width < bRect.left ||
                        aRect.left > bRect.left + bRect.width
                    )
                }
            }

            //canvas on mobile
            container.onglobaltouchmove = function (events) {
                const {x, y} = events.client

                const xPositionPercent = x / window.innerWidth
                const yPositionPercent = y / window.innerHeight

                const xPosition =
                    (visibleAreaMob.x.max - visibleAreaMob.x.min - window.innerWidth) * xPositionPercent +
                    visibleAreaMob.x.min
                const yPosition =
                    (visibleAreaMob.y.max - visibleAreaMob.y.min - window.innerHeight) * yPositionPercent +
                    visibleAreaMob.y.min

                gsap.to(this, {
                    x: move.x,
                    y: move.y,
                    duration: 4,
                })

                gsap.to(verticalMobileText, {
                    pixi: {
                        alpha: 1,
                    },
                })

                move.x = -xPosition
                move.y = -yPosition
            }

            if (window.innerWidth < 992) {
                const initSlide = 100
                gsap.to(container, {
                    x: window.innerWidth / 2 - initSlide,
                    y: window.innerHeight / 2 + initSlide / mobRatio,
                    duration: 7,
                })
            }

            //create mobile vertical text
            function createMobileVerticalText() {
                const text = new PIXI.Text(previousText, {
                    fontSize: 20,
                    lineHeight: 30,
                    color: 'red',
                })
                text.x = window.innerWidth / 2
                text.y = window.innerHeight / 2
                text.anchor.set(0.5, 0.5)
                return text
            }

            //update text based on new updated text
            function updateVerticalText(updatedText) {
                if (updatedText !== previousText) {
                    verticalMobileText.text = updatedText
                    previousText = updatedText
                }
            }

            setTimeout(() => {
                console.log('desktopPositions count: ------------------------>', desktopPositions.length)
                console.log('regeneratedDesktop count: ------------------------>', regeneratedDesktop)

                console.log('desktopPositions count: ------------------------>', mobilePositions.length)
                console.log('regeneratedMobile count: ------------------------>', regeneratedMobile)
            }, 5000)
        }
    }, [])

    return (
        <section className='canvas-gallery'>
            <canvas id='app' />
        </section>
    )
}

export default Index
