import image1 from '../assets/images/14_DC_20170707_WillaCreative_SkinHero_0509-400x600.jpg'
import image2 from '../assets/images/16_DC_20170707_WillaCreative_SkinHero_0664-400x600.jpg'
import image3 from '../assets/images/21_DC_20170707_WillaCreative_SkinHero_1090-401x600.jpg'
import image4 from '../assets/images/31c607d36cd6-serumgroup1587-1-67b860-600x600.png'
import image5 from '../assets/images/About-Facile-2021-600x569.png'
import image6 from '../assets/images/BARENECESSITY-4917_fd2ea95a-502b-4773-a41d-c37560926594-469x600.jpg'
import image7 from '../assets/images/bookappts-600x552.png'
import image8 from '../assets/images/Bragg-JStrutz-030821-0548-450x600.jpg'
import image9 from '../assets/images/Bragg-JStrutz-031021-1226-600x600.jpg'
import image10 from '../assets/images/Bragg-JStrutz-031021-1329-600x567.jpg'
import image11 from '../assets/images/Bragg-JStrutz-031021-1676-450x600.jpg'
import image12 from '../assets/images/Bragg-JStrutz-031021-1691-450x600.jpg'
import image13 from '../assets/images/Carolina_TheLuminary_Hand_D_010V1-400x600.jpg'
import image14 from '../assets/images/Carolina_TheLuminary_Sky_257V1-400x600.jpg'
import image15 from '../assets/images/Copper-JStrutz-040821-0260-600x450.jpg'
import image16 from '../assets/images/Copper-JStrutz-040821-0371-600x450.jpg'
import image17 from '../assets/images/Copper-Lifestyle-JStrutz-050321-0150-450x600.jpg'
import image18 from '../assets/images/Copper-Lifestyle-JStrutz-050321-0640-450x600.jpg'
import image19 from '../assets/images/Copper-Lifestyle-JStrutz-050321-1040-450x600.jpg'
import image20 from '../assets/images/Copper-Lifestyle-JStrutz-050321-1201-600x450.jpg'
import image21 from '../assets/images/Copper-Lifestyle-JStrutz-050321-1378-450x600.jpg'
import image22 from '../assets/images/CORE4-0345-469x600.jpg'
import image23 from '../assets/images/CORE4-0434-2copy-469x600.jpg'
import image24 from '../assets/images/CORE4-0601-469x600.jpg'
import image25 from '../assets/images/CORE4-0716-469x600.jpg'
import image26 from '../assets/images/CS-0014-400x600.jpg'
import image27 from '../assets/images/CS-0296-400x600.jpg'
import image28 from '../assets/images/CS-0429-400x600.jpg'
import image29 from '../assets/images/CS-0508-400x600.jpg'
import image30 from '../assets/images/CS-0980-400x600.jpg'
import image31 from '../assets/images/CS-2695-400x600.jpg'
import image32 from '../assets/images/Editorial_BodyOil_0128-1-400x600.jpg'
import image33 from '../assets/images/Eye-Concentrate-0802-1-600x400.jpg'
import image34 from '../assets/images/FaceCream_Texture_0654-600x400.jpg'
import image35 from '../assets/images/Facile-BareNecessity-PDP-7_def813ff-aaf2-40e6-a3d2-aa1c50a6b5a7-469x600.png'
import image36 from '../assets/images/film.000004-402x600.jpg'
import image37 from '../assets/images/film.000008-402x600.jpg'
import image38 from '../assets/images/film.000009-402x600.jpg'
import image39 from '../assets/images/film.000014.jpg'
import image40 from '../assets/images/g-0421-600x400.jpg'
import image41 from '../assets/images/Group_Gym_0451-400x600.jpg'
import image42 from '../assets/images/Group_Raquet-Ball_1706-600x400.jpg'
import image43 from '../assets/images/Group_Raquet-Ball_2009-600x400.jpg'
import image44 from '../assets/images/Group_Raquet-Ball_2496-400x600.jpg'
import image45 from '../assets/images/Group_Raquet-Ball_2814-600x400.jpg'
import image46 from '../assets/images/Group_Raquet-Ball_3508-600x400.jpg'
import image47 from '../assets/images/Group_Studio_3998-600x400.jpg'
import image48 from '../assets/images/image.32919-400x600.jpg'
import image49 from '../assets/images/image.32988-400x600.jpg'
import image50 from '../assets/images/image.33214-400x600.jpg'
import image51 from '../assets/images/image.33417-400x600.jpg'
import image52 from '../assets/images/image.33586-400x600.jpg'
import image53 from '../assets/images/image.33672.jpg'
import image54 from '../assets/images/image.33727-400x600.jpg'
import image55 from '../assets/images/image.33796-400x600.jpg'
import image56 from '../assets/images/IMG_6445-1.jpg'
import image57 from '../assets/images/Kuoth_Hand_Sky_075V3-400x600.jpg'
import image58 from '../assets/images/Kuoth_Hand_Sky_117V1-400x600.jpg'
import image59 from '../assets/images/Kuoth_Hero_LG_360-1V1-1-400x600.jpg'
import image60 from '../assets/images/Kuoth_Hero_Oil_048V2-400x600.jpg'
import image61 from '../assets/images/Laura_Hero_Water_001V1-400x600.jpg'
import image62 from '../assets/images/Laura_Raquet-Ball_1327-400x600.jpg'
import image63 from '../assets/images/Rebekah_Hero_LG_029V1.jpg'
import image64 from '../assets/images/Rebekah_Oil_LG_011V1.jpg'
import image65 from '../assets/images/Rebekah_Recharge002_Sky_045V2-400x600.jpg'
import image66 from '../assets/images/Rebekah_TheAnswer_Hands_LG_131V1-400x600.jpg'
import image67 from '../assets/images/Rebekah_TheOne_LG_414V2.jpg'
import image68 from '../assets/images/REN_SPF_MollyCranna_012919REN_SPF_MollyCranna_012819_0137-1-600x400.jpg'
import image69 from '../assets/images/REN_SPF_MollyCranna_012919REN_SPF_MollyCranna_012819_1119-1-600x400.jpg'
import image70 from '../assets/images/REN_SPF_MollyCranna_012919REN_SPF_MollyCranna_012819_1485-400x600.jpg'
import image71 from '../assets/images/RET-R2_VPM_210818_RETROUVE_MODEL-1_9236.jpg'
import image72 from '../assets/images/RET_VPM_210818_RETROUVE_MODEL-4-1_10890-400x600.jpg'
import image73 from '../assets/images/RET_VPM_210818_RETROUVE_MODEL-4_10615-400x600.jpg'
import image74 from '../assets/images/RET_VPM_210818_RETROUVE_MODEL-5_11220.jpg'
import image75 from '../assets/images/Skin-Hydrator-0731-600x400.jpg'
import image76 from '../assets/images/theone-0202-600x400.jpg'
import image77 from '../assets/images/Willa-Content.jpg'
import image78 from '../assets/images/Willa-Content-1.jpg'
import image79 from '../assets/images/Willa-Content-2.jpg'
import image80 from '../assets/images/Willa-Content-3.jpg'
import image81 from '../assets/images/Willa-Content-4.jpg'
import image82 from '../assets/images/Willa-Content-7.jpg'
import image83 from '../assets/images/Willa-Content-8.jpg'
import image84 from '../assets/images/Willa-Content-9.jpg'
import image85 from '../assets/images/Willa-Content-10.jpg'

const images = [
    {src: image1, x: 550, y: 400, xMob: 450, yMob: 150, title: 'SKINHERO'},
    {src: image2, x: 600, y: 640, xMob: 170, yMob: 15, title: 'SKINHERO'},
    {src: image3, x: 400, y: 800, xMob: 120, yMob: 350, title: 'SKINHERO'},
    {src: image4, x: 900, y: 700, xMob: 150, yMob: 750, title: 'FACILE'},
    {src: image5, x: 1200, y: 300, xMob: 450, yMob: 405, title: 'FACILE'},
    {src: image6, x: 1000, y: 500, xMob: -250, yMob: 500, title: 'FACILE'},
    {src: image7, x: 100, y: 850, xMob: -150, yMob: 305, title: 'FACILE'},
    {src: image8, x: 1500, y: 250, xMob: 569, yMob: 850, title: 'BRAGG'},
    {src: image9, x: 200, y: 150, xMob: -758, yMob: 150, title: 'BRAGG'},
    {src: image10, x: 700, y: 1100, xMob: -50, yMob: 1050, title: 'BRAGG'},
    {src: image11, x: 250, y: 630, xMob: 480, yMob: 745, title: 'BRAGG'},
    {src: image12, x: -300, y: 500, xMob: -350, yMob: 900, title: 'BRAGG'},
    {src: image13, x: -750, y: -50, xMob: 760, yMob: 1050, title: 'CAROLINA THE LUMINARY'},
    {src: image14, x:550, y: 50, xMob: 320, yMob: -150, title: 'CAROLINA THE LUMINARY'},
    {src: image15, x: 720, y: 320, xMob: -180, yMob: 771, title: 'COPPER'},
    {src: image16, x: 1340, y: 645, xMob: -450, yMob: 1200, title: 'COPPER'},
    {src: image17, x: -450, y: -280, xMob: -570, yMob: 850, title: 'COPPER'},
    {src: image18, x: -1050, y: -850, xMob: 957, yMob: -357, title: 'COPPER'},
    {src: image19, x: 800, y: 800, xMob: 250, yMob: 985, title: 'COPPER'},
    {src: image20, x: 1920, y: 720, xMob: -550, yMob: 550, title: 'COPPER'},
    {src: image21, x: 70, y: -30, xMob: 560, yMob: 50, title: 'COPPER'},
    {src: image22, x: 1700, y: 85, xMob: -655, yMob: -445, title: 'FACILE'},
    {src: image23, x: -500, y: -200, xMob: 585, yMob: 558, title: 'FACILE'},
    {src: image24, x: 1400, y: 878, xMob: -755, yMob: -157, title: 'FACILE'},
    {src: image25, x: 2100, y: -70, xMob: -569, yMob: 117, title: 'FACILE'},
    {src: image26, x: 810, y: 95, xMob: -875, yMob: 475, title: 'DERMALOGICA'},
    {src: image27, x: 1950, y: 255.77, xMob: 795, yMob: 117, title: 'DERMALOGICA'},
    {src: image28, x: 1785, y: 1080, xMob: -975, yMob: -150, title: 'DERMALOGICA'},
    {src: image29, x: 1988, y: 1300, xMob: 875, yMob: 575, title: 'DERMALOGICA'},
    {src: image30, x: 1300, y: -112, xMob: 575, yMob: -475, title: 'DERMALOGICA'},
    {src: image31, x: 2400, y: 577, xMob: -150, yMob: -50, title: 'DERMALOGICA'},
    {src: image32, x:  164, y: -200, xMob: -350, yMob: -150, title: 'RETROUVÉ'},
    {src: image33, x: -700, y: 511, xMob: 965, yMob: 217, title: 'RETROUVÉ'},
    {src: image34, x: -215, y: 94, xMob: 50, yMob: -150, title: 'RETROUVÉ'},
    {src: image35, x: -150, y: -81, xMob: 359, yMob: 1250, title: 'FACILE'},
    {src: image36, x: 2357, y: 1200, xMob: 1057, yMob: 457, title: 'DEDCOOL'},
    {src: image37, x: -2078, y: -300, xMob: 757, yMob: -157, title: 'DEDCOOL'},
    {src: image38, x: -2554, y: 1280, xMob: 20, yMob: 1150, title: 'DEDCOOL'},
    {src: image39, x: 1980, y: -207, xMob: -250, yMob: -450, title: 'DEDCOOL'},
    {src: image40, x: 2080, y: 405, xMob: 200, yMob: 1485, title: 'SYMBIOME'},
    {src: image41, x: 2333, y: 796, xMob: 480, yMob: 1150, title: 'FP MOVEMENT'},
    {src: image42, x: 2684, y: -500, xMob: -750, yMob: 1150, title: 'FP MOVEMENT'},
    {src: image43, x: 2458, y: 15, xMob: -750, yMob: -550, title: 'FP MOVEMENT'},
    {src: image44, x: -1251, y: 344, xMob: -750, yMob: 1550, title: 'FP MOVEMENT'},
    {src: image45, x: 1805, y: 1315, xMob: 480, yMob: -1150, title: 'FP MOVEMENT'},
    {src: image46, x: 1650, y: 960, xMob: 1157, yMob: -157, title: 'FP MOVEMENT'},
    {src: image47, x: 1500, y: 1050, xMob: -50, yMob: -750, title: 'FP MOVEMENT'},
    {src: image48, x: 1000, y: 1075, xMob: -424, yMob: -745, title: 'DEDCOOL'},
    {src: image49, x: 1100, y: 1200, xMob: -600, yMob: 1755, title: 'DEDCOOL'},
    {src: image50, x: 2200, y: -150, xMob: 700, yMob: -1255, title: 'DEDCOOL'},
    {src: image51, x: 2400, y: -400, xMob: 900, yMob: -1355, title: 'DEDCOOL'},
    {src: image52, x: -950, y: 1100, xMob: 1100, yMob: 1555, title: 'DEDCOOL'},
    {src: image53, x: 2508, y: 130, xMob: 1180, yMob: 1255, title: 'DEDCOOL'},
    {src: image54, x: -1550, y: 541, xMob: 1287, yMob: -357, title: 'DEDCOOL'},
    {src: image55, x: -1180, y: 930, xMob: 857, yMob: -557, title: 'DEDCOOL'},
    {src: image56, x: -1200, y: -250, xMob: -750, yMob: 850, title: 'DEDCOOL'},
    {src: image57, x: -505, y: -850, xMob: -400, yMob: 1550, title: 'SYMBIOME'},
    {src: image58, x: -1540, y: -500, xMob: 559, yMob: 1550, title: 'SYMBIOME'},
    {src: image59, x: -1452, y: -650, xMob: 800, yMob: 1555, title: 'SYMBIOME'},
    {src: image60, x: -1605, y: 1080, xMob: 1265, yMob: 470, title: 'SYMBIOME'},
    {src: image61, x: 2461, y: 1300, xMob: 1080, yMob: 755, title: 'SYMBIOME'},
    {src: image62, x: -1250, y: 900, xMob: 1080, yMob: 755, title: 'FP MOVEMENT'},
    {src: image63, x: -30, y: -10, xMob: 970, yMob: -977, title: 'SYMBIOME'},
    {src: image64, x: -650, y: 1400, xMob: 650, yMob: 1950, title: 'SYMBIOME'},
    {src: image65, x: 350, y: 1300, xMob: -1050, yMob: 1250, title: 'SYMBIOME'},
    {src: image66, x: 400, y: -150, xMob: -1250, yMob: 200, title: 'SYMBIOME'},
    {src: image67, x: -1120, y: -150, xMob: -1450, yMob: 1750, title: 'SYMBIOME'},
    {src: image68, x: -1600, y: 1100, xMob: 250, yMob: 1700, title: 'REN'},
    {src: image69, x: -2000, y: 1850, xMob: 1250, yMob: 1750, title: 'REN'},
    {src: image70, x: 150, y: 980, xMob: -1575, yMob: 565, title: 'REN'},
    {src: image71, x: -70, y: 980, xMob: 650, yMob: 1950, title: 'RETROUVÉ'},
    {src: image72, x: 2400, y: -1300, xMob: 50, yMob: 2000, title: 'RETROUVÉ'},
    {src: image73, x: -2457, y: 1452, xMob: -1275, yMob: 475, title: 'RETROUVÉ'},
    {src: image74, x: -2600, y: 1580, xMob: -1050, yMob: 980, title: 'RETROUVÉ'},
    {src: image75, x: 450, y: 1400, xMob: -1375, yMob: 755, title: 'RETROUVÉ'},
    {src: image76, x: -800, y: -600, xMob: -1075, yMob: -550,title: 'SYMBIOME'},
    {src: image77, x: -1400, y: 930, xMob: -1475, yMob: 375, title: 'HONEYPOT'},
    {src: image78, x: 1410, y: -200, xMob: -975, yMob: 675, title: 'HONEYPOT'},
    {src: image79, x: -834, y: -1520, xMob: 987, yMob: -757, title: 'HONEYPOT'},
    {src: image80, x: -1550, y: -750, xMob: 1350, yMob: 1355, title: 'HONEYPOT'},
    {src: image81, x: 2488, y: -750, xMob: 650, yMob: 1250, title: 'HONEYPOT'},
    {src: image82, x: 2600, y: 500, xMob: -900, yMob: 1955, title: 'HONEYPOT'},
    {src: image83, x: -555, y: -1500, xMob: -1250, yMob: 1550, title: 'HONEYPOT'},
    {src: image84, x: 100, y: -750, xMob: -1050, yMob: 1250, title: 'HONEYPOT'},
    {src: image85, x: 2950, y: -850, xMob: -1450, yMob: 1050, title: 'HONEYPOT'}
]

export default images
